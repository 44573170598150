import type { VNode } from 'vue';
import { Component, BaseComponent, Prop, namespace, VariationBase, Watch } from '@zento-lib/components';
import { AppContextStore, KEY as appContextKey } from 'theme/@types/zento/stores/applicationContext';
import { TopNavigationData } from 'theme/stores/navigation/types';
import { NavigationStore } from 'theme/stores/navigation/navigation';
import { IVSFStoreUI, KEY as UIKey } from 'theme/@types/vsf/stores/ui';

import type { MenuItem } from '../types/MenuTypes';
import type { CategoryTypes } from '../../atom/types/CategoryTypes';

import type { IMenuList } from './List.d';

const uiStore = namespace<IVSFStoreUI>(UIKey);
const appContextStore = namespace<AppContextStore>(appContextKey);

/**
 * Menu List
 *
 * Main menu list renderer component
 **/
@Component({})
@VariationBase
export class MenuList extends BaseComponent<IMenuList, unknown> {
  protected static V = {
    menuList1: () => import('./variations/list1/list1').then((m) => m.MenuList1),
    menuList2: () => import('./variations/list2/list2').then((m) => m.MenuList2),
    menuList3: () => import('./variations/list3/list3').then((m) => m.MenuList3),
    menuList4: () => import('./variations/list4/list4').then((m) => m.MenuList4),
    menuList5: () => import('./variations/list5/list5').then((m) => m.MenuList5),
    menuList6: () => import('./variations/list6/list6').then((m) => m.MenuList6),
    menuList7: () => import('./variations/list7/list7').then((m) => m.MenuList7),
    menuList8: () => import('./variations/list8/list8').then((m) => m.MenuList8),
    menuList9: () => import('./variations/list9/list9').then((m) => m.MenuList9),
    menuList10: () => import('./variations/list10/list10').then((m) => m.MenuList10),
    menuList11: () => import('./variations/list11/list11').then((m) => m.MenuList11),
    menuList12: () => import('./variations/list12/list12').then((m) => m.MenuList12),
  };

  protected static T = {
    seeAllCategories: 'see_all_categories',
    seeAllSubcategories: 'subcategory_view_all_link',
    seeAll: 'see_all',
  };

  protected navigationStore = new NavigationStore();
  protected lookupMap: Record<string, TopNavigationData>;

  @uiStore.State('submenu')
  protected submenu: IVSFStoreUI['state']['submenu'];

  /**
   * Menu navigation items
   */
  @Prop({ type: Array, required: true, default: () => [] })
  items: Array<TopNavigationData | VNode>;

  /**
   * Root Category Children's
   */
  @Prop({ type: Array, required: false, default: () => [] })
  rootCategoryChildren?: CategoryTypes[];

  /**
   * Contains all promoted menu items
   */
  @Prop({ type: Array, required: false, default: () => [] })
  promotedCategories?: MenuItem[];

  /**
   * Maximum number of promoted items to be rendered
   */
  @Prop({ type: Number, required: false, default: 2 })
  promotedItemsLimit?: number;

  /**
   * Maximum number of feature products to be rendered
   */
  @Prop({ type: Number, required: false, default: 7 })
  promotedFeatureProductsLimit?: number;

  @Prop({ type: Number, required: true })
  depth: number;

  /**
   * Add same styling for desktop drawer
   */
  @Prop({ type: Boolean, required: false, default: false })
  desktopDrawer?: boolean;

  /**
   * Determines root category name
   */
  @Prop({ type: String, required: false })
  rootCategoryName?: string;

  @appContextStore.Getter('isMobile')
  protected isMobile: boolean;

  @appContextStore.Getter('isDesktop')
  protected isDesktop: boolean;

  @Watch('rootCategoryChildren')
  onRootCategoriesChanged(newVal) {
    this.lookupMap = this.unfoldCategoryTree(newVal);
  }

  protected conditioning = {
    categories: { group: true, category: true },
    external: { cms_page: true, external: true, blog: true },
  };

  created() {
    this.lookupMap = this.unfoldCategoryTree(this.rootCategoryChildren);
  }

  beforeMount() {
    this.nextSelection = this.nextSelection.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  /**
   * Compute a map with promoted category items per level
   */
  protected get promotedItems() {
    return this.promotedCategories
      .filter((cat: CategoryTypes) => cat.level <= 3)
      .concat(this.items.filter((item: TopNavigationData) => this.isPromoted(item, 3)))
      .slice(0, this.promotedItemsLimit);
  }

  /**
   * Handler for subcategory navigation
   */
  protected nextSelection(ev: Event, item: CategoryTypes) {
    if ((this.desktopDrawer || !this.isDesktop) && item.children_data.length > 0) {
      ev.preventDefault();
      this.broadcast('menu/selection', item.id);
    }
  }

  /**
   * Handler for close navigation
   */
  protected closeMenu() {
    this.$store.commit('ui/setSidebar', false);
    this.$store.commit('ui/setOverlay', false);

    // Reset menu to first level
    this.$store.state.ui.submenu.path = [];

    if (this.desktopDrawer || !this.isDesktop) {
      document.body.style.overflow = 'visible';
    }
  }

  /**
   * Determines if current category has promoted type
   */
  protected isPromoted(cat: CategoryTypes, level: number) {
    return cat.category_type && cat.category_type === 2 && cat.level >= level;
  }

  /**
   * Show category parent name
   */
  protected get getParentItem() {
    return this.lookupMap[this.submenu.path[this.submenu.path.length - 2]];
  }

  private unfoldCategoryTree(categories: any[], acc = {}) {
    return categories.reduce((ret, c) => {
      return {
        ...ret,
        [c.id]: c,
        ...this.unfoldCategoryTree(c.children_data || [], acc),
      };
    }, acc);
  }
}
